
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import './index.css'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


 ////////////////////********Old ReactDOM*******///////////////////// 

// import React from "react";
// import ReactDOM  from "react-dom";
// import './index.css'
// import App from './App';


// ReactDOM.render(<App/>, document.getElementById('root'));

